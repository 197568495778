<template>
	<div 
		id="contentClick" 
		:class="cls" 
		@click.prevent.stop="closeMenu" 
	/>
	<aside :class="cls">
		<img src="../assets/logo-jk-header.jpg" alt="Cartório JK" />
		<user-vue />
		<SideMenuVue />
	</aside>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'
import SideMenuVue from './SideMenu.vue'
import UserVue from "./User.vue"

export default {
	components: {
		UserVue,
		SideMenuVue,
	},
	setup() {
		const store = useStore();
		/*
			Calculando a classe de acordo com 
			a info de state que precisamos
		*/
		const cls = computed(() => store.state.aside.isOpen ? "open" : "")
		const closeMenu = () => store.commit("closeMenu")
		return {
			cls,
			closeMenu
		}
	}
}

</script>

<style scoped>
aside {
	background: var(--el-color-primary);
	color: var(--el-color-primary-light-9);
	z-index: 2001;
	padding-top: var(--headerHeight);
	position: fixed;
	width: 280px;
	overflow-y: auto;
	height: calc(100vh - var(--headerHeight));
	transform: translate(-100%);
	transition: transform 450ms ease-in;

}

/*
		Invertendo as scores da barra de rolagem no aside
	*/
aside::-webkit-scrollbar {
	background-color: var(--el-color-primary);
}

aside::-webkit-scrollbar-thumb {
	background: var(--el-color-primary-light-5);
}

/*
		Layout com aside a partir do tamanho médio
	*/
@media (--medium-viewport) {
	aside {
		grid-area: aside;
		display: block;
		transform: translate(0%);
		padding: var(--spacing-large) 0;
		height: calc(100vh - calc(var(--spacing-large) * 2));
	}
}

.open {
	transform: translate(0%);
	transition: transform 150ms ease-in;
}

#contentClick {
	z-index: 2000;
	pointer-events: none;
	width: 100vw;
	height: 100vh;
	position: fixed;
	grid-area: 1/-1;
}

#contentClick.open {
	pointer-events: all;
}

img {
	display: none;
}

@media (--medium-viewport) {
	img {
		display: block;
		width: calc(100% - calc(var(--spacing-large) * 2));
		border-radius: calc(var(--spacing));
		margin: var(--spacing-large);
	}
}
</style>
