<template>
	<div class="user" v-show="user">
		<el-avatar class="avatar" :icon="Avatar" />
		<span class="username">{{ user.profile.name }}</span>
	</div>
</template>

<script setup>
import { computed } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { Avatar } from '@element-plus/icons-vue'

let store = useStore()
let user = computed(() => store.state.oidc.user );
</script>

<style scoped >

	.user {
		margin: var(--spacing-large) var(--spacing-large);
		display: flex;
		gap: var(--spacing-large);
		align-content: center;
		background-color: var(--el-color-primary-light-1) !important;
		border-radius: var(--spacing-large);
	}
	.avatar { 
		min-width: 40px;
		align-self: center;
	}
	.username {
		padding: var(--spacing) var(--spacing-large) var(--spacing) 0;
		align-self: center;
		font-weight: bold;
	}
</style>